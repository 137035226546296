<template>
  <base-material-card
    icon="mdi-plus"
    color="secondary"
    :title="title"
  >
    <v-form
      ref="customerForm"
      lazy-validation
      @submit.prevent="addCustomer"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-radio-group
            v-model="customer.user"
            row
          >
            <v-radio
              label="Personal"
              value="user"
            />
            <v-radio
              label="Commercial"
              value="company"
            />
          </v-radio-group>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="customer.status_type_id"
            label="Status *"
            item-text="name"
            item-value="id"
            :items="status.items"
            :loading="status.loading"
            :rules="[required]"
          />
        </v-col>
        <v-col
          v-if="isLead"
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="customer.temperature_type_id"
            :items="mixinItems.temperatureTypes"
            item-value="id"
            item-text="name"
            prepend-icon="mdi-thermometer-alert"
            label="Temperature"
            clearable
            :loading="loadingMixins.temperatureTypes"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-if="!isArchive"
            v-model="customer.priority_type_id"
            :items="mixinItems.priorityTypes"
            item-value="id"
            item-text="name"
            prepend-icon="mdi-priority-high"
            label="Priority"
            clearable
            :loading="loadingMixins.priorityTypes"
          />
        </v-col>
      </v-row>

      <!-- User Form -->
      <v-row v-if="customer.user === 'user'">
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="customer.first_name"
            label="First Name *"
            :rules="[required]"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="customer.last_name"
            label="Last Name *"
            :rules="[required]"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="customer.ssn"
            label="SSN"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="customer.email"
            label="Email *"
            :rules="[required, email]"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="customer.user_class_id"
            label="Class *"
            item-text="name"
            item-value="id"
            :items="mixinItems.userClasses.filter(userClass => userClass.is_customer)"
            :loading="loadingMixins.userClasses"
            :rules="[required]"
            @change="fetchCompanies"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="customer.company_id"
            label="Company"
            item-text="name"
            item-value="id"
            :items="companies"
            :loading="loadingCompanies"
          />
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="customer.name"
            label="Name *"
            :rules="[required]"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="customer.tin"
            label="Federal Employer Identification Number (FEIN)"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="customer.dba"
            label="Doing Business As Name"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="customer.company_class_id"
            label="Class *"
            item-text="name"
            item-value="id"
            :items="mixinItems.companyClasses.filter(companyClass => companyClass.is_customer)"
            :loading="loadingMixins.companyClasses"
            :rules="[required]"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="customer.company_type_id"
            label="Type"
            item-text="type"
            item-value="id"
            :items="mixinItems.companyTypes"
            :loading="loadingMixins.companyTypes"
          />
        </v-col>
      </v-row>

      <v-row
        justify="end"
        no-gutters
      >
        <v-btn
          color="success"
          type="submit"
          small
          :loading="adding"
        >
          <v-icon left>
            mdi-content-save
          </v-icon>
          Add
        </v-btn>
      </v-row>
    </v-form>
  </base-material-card>
</template>

<script>
  import axios from 'axios'
  import { mapActions } from 'vuex'
  import { Api } from '@/apis'
  import { fetchInitials } from '@/mixins/fetchInitials'
  import { MIXINS } from '@/constants'

  export default {
    mixins: [
      fetchInitials([
        MIXINS.userClasses,
        MIXINS.companyClasses,
        MIXINS.companyTypes,
        MIXINS.leadStatuses,
        MIXINS.clientStatuses,
        MIXINS.archiveStatuses,
        MIXINS.priorityTypes,
        MIXINS.temperatureTypes,
      ]),
    ],

    props: {
      title: {
        type: String,
        default: '',
      },
      isLead: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      customer: {
        user: 'user',
      },
      adding: false,
      required: value => !!value || 'This field is required.',
      email: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      loadingCompanies: false,
      companies: [],
    }),

    computed: {
      status () {
        if (this.$route.fullPath.includes('clients')) {
          return {
            items: this.mixinItems.clientStatuses,
            loading: this.loadingMixins.clientStatuses,
          }
        } else if (this.$route.fullPath.includes('leads')) {
          return {
            items: this.mixinItems.leadStatuses,
            loading: this.loadingMixins.leadStatuses,
          }
        } else {
          return {
            items: this.mixinItems.archiveStatuses,
            loading: this.loadingMixins.archiveStatuses,
          }
        }
      },

      isArchive () {
        return this.$route.fullPath.includes('archives')
      },
    },

    methods: {
      ...mapActions({
        showSnackBar: 'showSnackBar',
      }),

      async addCustomer () {
        if (this.$refs.customerForm.validate()) {
          this.adding = true
          const url = this.customer.user === 'user' ? 'individual/individual' : 'company/company'
          await new Api().post({
            url,
            request: this.customer,
          })
          this.adding = false
          this.$refs.customerForm.reset()
        }
      },

      async fetchCompanies (userClass) {
        this.loadingCompanies = true
        try {
          const response = await axios.get('customer/companies/' + userClass)
          this.companies = response.data
        } catch (error) {
          this.showSnackBar({ text: error, color: 'error' })
        }
        this.loadingCompanies = false
      },
    },
  }
</script>
